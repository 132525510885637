































































































































































































































































































import VueBase from '../../VueBase'
import { Component, Watch } from 'vue-property-decorator'
import { ProjectObj } from './types'
import { formatTimestamp } from '@/utils/utils'
import request from '@/utils/request'
import * as echarts from 'echarts'
import { EChartsOption } from 'echarts'
import VulListComponent from './VulListComponent.vue'
import ApiList from './apiList.vue'
import ScaList from '../sca/ScaList.vue'
import merge from 'webpack-merge'

import Distribution from './components/distribution.vue'
import Trend from './components/trend.vue'
import Type from './components/type.vue'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
])
@Component({
  name: 'ProjectDetail',
  components: {
    VulListComponent,
    ScaList,
    ApiList,
    Distribution,
    Trend,
    Type,
  },
})
export default class ProjectDetail extends VueBase {
  beforeRouteEnter(to: any, from: any, next: any) {
    next()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.meta.i18n === 'menu.vulnDetail') {
      this.$store.dispatch('setRouteInfo', [])
    } else {
      this.$store.dispatch('setRouteInfo', ['ProjectIndex', 'ProjectDetail'])
    }
    console.log('@store', this.$store.getters.vulnRouteInfo)
    next()
  }
  formatTimestamp(time: number) {
    return formatTimestamp(time)
  }
  private exp_page = 1
  private exp_total = 0
  private type = 'docx'
  private selectTab = 'desc'
  private projectObj: ProjectObj = {
    id: 0,
    mode: '',
    name: '',
    owner: '',
    latest_time: '',
    versionData: {},
    agent_languag: [],
  }
  private showApiListFlag = false
  private versionTemp: any = {}
  private versionList: any[] = []
  private versionFlag = false
  private enterVersionDialog() {
    if (this.versionList.some((item) => item.isEdit)) {
      this.$message.error(this.$t('views.projectDetail.beforeClose') as string)
      return
    }
    this.versionFlag = false
  }

  private async versionCurrent(item: any) {
    const res: any = await this.services.project.versionCurrent({
      version_id: item.version_id,
      project_id: this.$route.params.pid,
    })
    if (res.status !== 201) {
      this.$message({
        type: 'error',
        message: res.msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'success',
        message: res.msg,
        showClose: true,
      })
      this.versionList.forEach((i) => (i.current_version = 0))
      item.current_version = 1
      // await this.showApiList()
      await this.projectsSummary()
      await this.getVersionList()
    }
  }

  goProjectList() {
    this.$router.push({
      name: 'projectManage',
    })
  }

  private async changeActive(e: any) {
    this.selectTab = e
    this.$router.replace({
      query: merge(this.$route.query, { activeName: e }) as any,
    })
    // this.$set(this.$route.query, 'activeName', e)
    if (e === 'desc') {
      await this.projectsSummary(this.projectObj.versionData.version_id)
    }
  }

  private editVersion(item: any) {
    if (this.versionList.some((item) => item.isEdit)) {
      this.$message({
        type: 'error',
        message: this.$t('views.projectDetail.hasEdit') as string,
        showClose: true,
      })
      return
    }
    this.$set(item, 'isEdit', true)
    Object.assign(this.versionTemp, item)
  }
  private async enterVersion(item: any, index: number) {
    if (
      this.versionList.some(
        (i, k) => i.version_name === item.version_name && k !== index
      )
    ) {
      this.$message({
        type: 'warning',
        message: this.$t('views.projectDetail.hasSame') as string,
        showClose: true,
      })
      return
    }
    if (!item.version_id) {
      const res: any = await this.services.project.versionAdd({
        ...item,
        project_id: this.$route.params.pid,
      })
      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: res.msg,
        showClose: true,
      })
      item.version_id = res.data.version_id
      this.versionTemp = {}
      this.$set(item, 'isEdit', false)
    } else {
      const res: any = await this.services.project.versionEdit({
        ...item,
        project_id: this.$route.params.pid,
      })
      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: res.msg,
        showClose: true,
      })
      this.versionTemp = {}
      this.$set(item, 'isEdit', false)
    }
  }
  private cancelVersion(item: any, index: number) {
    if (!item.version_id) {
      this.versionList.splice(index, 1)
      return
    }
    Object.assign(item, this.versionTemp)
    this.$set(item, 'isEdit', false)
    this.versionTemp = {}
  }
  private deleteVersion(item: any, index: number) {
    this.$confirm(
      this.$t('views.projectDetail.warningInfo') as string,
      this.$t('views.projectDetail.warning') as string,
      {
        confirmButtonText: this.$t(
          'views.projectDetail.enterVersion'
        ) as string,
        cancelButtonText: this.$t(
          'views.projectDetail.cancelVersion'
        ) as string,
        type: 'warning',
      }
    ).then(async () => {
      const res: any = await this.services.project.versionDelete({
        version_id: item.version_id,
        project_id: this.$route.params.pid,
      })
      if (res.status != 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        this.versionList.splice(index, 1)
      }
    })
  }

  private addVersion() {
    if (this.versionList.some((item) => item.isEdit)) {
      this.$message({
        showClose: true,
        message: this.$t('views.projectDetail.hasEdit') as string,
        type: 'error',
      })
      return
    }
    this.versionList.push({
      version_name: '',
      description: '',
      isEdit: true,
    })
  }
  private async changeVersion(value: any) {
    // await this.showApiList()
    // await this.projectsSummary()
    this.$nextTick(() => {
      if (this.selectTab === 'desc') {
        this.projectsSummary(value)
      } else if (this.selectTab === 'vul') {
        const v: any = this.$refs.vulListComponent
        v.getTableData(true)
        v.vulnSummary()
      } else if (this.selectTab === 'component') {
        const c: any = this.$refs.componentList
        c.getTableData(true)
        c.scaSummary()
      } else if (this.selectTab === 'apiList') {
        const a: any = this.$refs.apiList
        a.searchChange()
      }
    })
  }
  async showApiList() {
    const res = await this.services.project.searchApi({
      page_size: 1,
      project_id: this.$route.params.pid,
      version_id: this.projectObj.versionData.version_id,
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
    }
    if (res.data?.length > 0) {
      this.showApiListFlag = true
    } else {
      this.showApiListFlag = false
      if (this.selectTab === 'apiList') {
        this.selectTab = 'desc'
      }
    }
  }

  @Watch('$route.params.pid', { immediate: true })
  async init() {
    if (this.$route.query.activeName) {
      this.selectTab = this.$route.query.activeName as string
    } else {
      this.selectTab = 'desc'
    }
    if (!this.$route.params.pid) {
      return
    }
    // await this.showApiList()
    await this.projectsSummary()
    await this.getVersionList()
  }
  private offsetHeight = 0
  private async projectsSummary(id?: string) {
    const { status, msg, data } = await this.services.project.projectsSummary(
      this.$route.params.pid,
      id
    )
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }

    this.projectObj = {
      ...data,
      name: data.name,
      latest_time: formatTimestamp(data.latest_time),
    }
  }
  showVersion() {
    this.versionFlag = true
  }
  getVersionList() {
    this.services.project
      .versionList(this.$route.params.pid)
      .then((res: any) => {
        if (res.status === 201) {
          this.versionList = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            showClose: true,
          })
        }
      })
  }

  private getTagColoe(language: string) {
    switch (language) {
      case 'JAVA':
        return 'danger'
      case 'PYTHON':
        return ''
      default:
        return ''
    }
  }
}
