





































































































































































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '../../VueBase'
import SearchCard from '@/views/taint/searchCard.vue'
@Component({
  name: 'ApiList',
  components: {
    SearchCard,
  },
})
export default class Index extends VueBase {
  @Prop() versionId: number | undefined
  @Prop(String) projectId!: string
  private searchObj = {
    method: '',
    status: '',
    url: '',
  }
  private dataEnd = false
  private page_index = 1
  private pageSize = 20
  private coverRate = ''
  private totalCount = ''
  private coverCount = ''
  private openCollapse = [0]
  private apiList = []
  private getColor(type: string) {
    switch (type) {
      case 'GET':
        return { borderColor: '#61affe', bgColor: 'rgba(97,175,254,.1)' }
      case 'POST':
        return { borderColor: '#49cc90', bgColor: 'rgba(73,204,144,.1)' }
      case 'PUT':
        return { borderColor: '#fca130', bgColor: 'rgba(252,161,48,.1)' }
      case 'DELETE':
        return { borderColor: '#f93e3e', bgColor: 'rgba(249,62,62,.1)' }
      default:
        return { borderColor: '#909399', bgColor: '#EBEEF5' }
    }
  }
  private getType(type: string) {
    switch (type) {
      case 'GET':
        return 'iast-tag-get'
      case 'POST':
        return 'iast-tag-post'
      case 'PUT':
        return 'iast-tag-put'
      case 'DELETE':
        return 'iast-tag-delete'
      default:
        return 'iast-tag-other'
    }
  }
  private async startView(item: any) {
    if (item.showSend) {
      item.showSend = false
    }
    const res = await this.services.project.relationrequest({
      project_id: this.projectId,
      version_id: this.versionId,
      api_route_id: item.id,
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
    }
    item.url = res.data.url
    item.poolId = res.data.id
    item.req_header_fs = res.data.req_header_fs
    item.req_data = res.data.req_data
    item.res_body = res.data.res_body
    item.res_header = res.data.res_header
    item.update_time = res.data.update_time
    if (res.status)
      this.$nextTick(() => {
        item.showSend = true
      })
  }
  private startSend(item: any) {
    if (item.showSend) {
      item.showSend = false
    }
    this.$nextTick(() => {
      item.showSend = true
    })
  }
  private async getApiList() {
    this.loadingStart()
    const ids = this.apiList.map((item: any) => {
      return item.id
    })
    const res = await this.services.project.searchApi({
      exclude_ids: String(ids),
      page_size: this.pageSize,
      page_index: this.page_index,
      uri: this.searchObj.url,
      http_method: this.searchObj.method,
      is_cover: this.searchObj.status,
      project_id: this.projectId,
      version_id: this.versionId,
    })
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
    }
    const apiList = res.data.map((item: any) => {
      return {
        id: item.id,
        agent: item.agent,
        poolId: -1,
        parameters: item.parameters,
        path: item.path,
        vulnerablities: item.vulnerablities,
        method: item.method.apimethod,
        httpMethod: item.method.httpmethods[0],
        description: item.description,
        is_cover: item.is_cover,
        return_type: item.responses[0] && item.responses[0].return_type,
        return_type_shortcut:
          item.responses[0] && item.responses[0].return_type_shortcut,
        req_header_fs: `${item.method.httpmethods[0]} ${item.path} HTTP/1.1`,
        req_data: '',
        res_header: '',
        res_body: '',
        showSend: false,
      }
    })
    this.apiList = this.apiList.concat(apiList)
    if (apiList.length < this.pageSize) {
      this.dataEnd = true
    }
  }

  private searchChange() {
    // First filter the front-end to meet the conditions, and then request the back-end to prevent excessive pressure on the database
    this.apiList = this.apiList.filter(
      (item: any) =>
        item.method.indexOf(this.searchObj.method) > -1 &&
        item.path.indexOf(this.searchObj.url) > -1 &&
        (this.searchObj.status === '' || this.searchObj.status === undefined
          ? true
          : item.is_cover === this.searchObj.status)
    )
    this.page_index = 1
    this.dataEnd = false
    this.getApiList()
  }

  private async cover() {
    const res = await this.services.project.coverRate({
      project_id: this.projectId,
      version_id: this.versionId,
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
    }
    this.coverRate = res.data.cover_rate
    this.totalCount = res.data.total_count
    this.coverCount = res.data.covered_count
  }

  async mounted() {
    this.cover()
    this.pageSize = Math.ceil((document.body.clientHeight - 280) / 48)
    await this.getApiList()
    window.addEventListener('scroll', this.myScroll)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.myScroll)
  }

  private myScroll() {
    const bottomWindow =
      document.documentElement.scrollTop + window.innerHeight >
      document.documentElement.offsetHeight - 1
    if (bottomWindow) {
      if (!this.dataEnd) {
        this.page_index += 1
        this.getApiList()
      }
    }
  }
}
