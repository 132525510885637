





































































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import { VulnListObj } from '@/views/vuln/types'
import VulnCard from '@/views/vuln/components/vulnCard.vue'
import ScaCard from '@/views/vuln/components/ScaCard.vue'
import ScanCard from '@/views/vuln/components/ScanCard.vue'

@Component({
  name: 'VulListComponent',
  components: { VulnCard, ScaCard, ScanCard },
})
export default class VulListComponent extends VueBase {
  @Prop() version: number | string | undefined
  @Prop() projectId!: string | number | undefined
  private inteRules = {
    obj: [{ required: true, message: '请选择项目', trigger: 'blur' }],
    title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
    type: [{ required: true, message: '请选择缺陷类型', trigger: 'blur' }],
    level: [{ required: true, message: '请选择缺陷等级', trigger: 'blur' }],
  }
  private projectOtions: any = []
  private typeOptions: any = []
  private priorityOtions: any = []
  private settingInte: any = []
  private activeType: any = ''
  private pageInfo = {}
  private statusSelect: any = ''

  private vulnType = 'vuln'
  changeVulnType(type: any) {
    this.vulnType = type
    this.reset()
    this.vulnSummary()
  }

  async changeStatus(e: any) {
    this.$nextTick(() => {
      this.statusSelect = ''
    })
    if (!this.tableData.some((item: any) => item.checked)) {
      this.$message({
        type: 'warning',
        message: '请选择需要更改状态的漏洞',
        showClose: true,
      })
      return
    }
    const ids = this.tableData
      .map((item) => {
        if (item.checked) {
          return item.id
        }
      })
      .filter((item) => item)
    const res = await this.services.vuln.changeStatus({
      vul_ids: ids,
      status_id: e,
    })

    if (res.status === 201) {
      this.$message.success(res.msg)
      this.newSelectData()
      return
    }
    this.$message.error(res.msg)
  }

  private page = 1
  private pageSize = 20
  private dataEnd = false
  private tableData: Array<any> = []
  private searchOptionsObj: any = {
    level: [
      {
        name: '高危',
        num: 0,
        id: 1,
      },
      {
        name: '中危',
        num: 0,
        id: 2,
      },
      {
        name: '低危',
        num: 0,
        id: 3,
      },
      {
        name: '无风险',
        num: 0,
        id: 4,
      },
      {
        name: '提示',
        num: 0,
        id: 5,
      },
    ],
    status: [
      {
        name: '待验证',
        num: 0,
        id: 1,
      },
      {
        name: '验证中',
        num: 0,
        id: 2,
      },
      {
        name: '已确认',
        num: 0,
        id: 3,
      },
      {
        name: '已忽略',
        num: 0,
        id: 4,
      },
      {
        name: '已处理',
        num: 0,
        id: 5,
      },
    ],
    availability: [],
    hook_type: [],
    language: [
      {
        name: 'JAVA',
        num: 0,
        id: 1,
      },
      {
        name: 'PYTHON',
        num: 0,
        id: 2,
      },
      {
        name: 'PHP',
        num: 0,
        id: 3,
      },
      {
        name: 'GO',
        num: 0,
        id: 4,
      },
    ],
    orderOptions: [
      {
        label: '漏洞级别',
        value: 1,
      },
      {
        label: '发现时间',
        value: 2,
      },
      {
        label: '最新活跃',
        value: 3,
      },
      {
        label: '状态',
        value: 4,
      },
    ],
    statusOptions: [],
  }

  private async getStatus() {
    const res = await this.services.vuln.vulStatus()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.searchOptionsObj.statusOptions = res.data.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }

  private reset() {
    this.searchObj = {
      level_str: [],
      project_str: [],
      order_type: '',
      keywords: '',
      status_str: [],
      source_type_str: [],
      availability_str: [],
      hook_type_str: [],
      language_str: [],
      sort: null,
    }
    this.newSelectData()
  }

  private searchObj: any = {
    level_str: [],
    project_str: [],
    order_type: '',
    keywords: '',
    status_str: [],
    source_type_str: [],
    availability_str: [],
    hook_type_str: [],
    language_str: [],
    sort: null,
  }

  created() {
    this.getStatus()
    this.getTableData()
    this.vulnSummary()
  }

  private async querySearchAsync(queryString: string, cb: any) {
    const res = await this.services.setting.searchProject({ name: queryString })
    if (res.status === 201) {
      const data = res.data.map((item: any) => {
        return {
          value: item.name,
          id: item.id,
        }
      })
      cb(data)
    }
  }

  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }

  private deleteVul(type: string) {
    this.$confirm(
      '该操作将会删除选中漏洞',
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(async () => {
      let res: any = {}
      if (!this.tableData.some((item: any) => item.checked)) {
        this.$message({
          type: 'warning',
          message: '请选择需要删除的漏洞',
          showClose: true,
        })
        return
      }
      const ids = this.tableData
        .map((item) => {
          if (item.checked) {
            return item.id
          }
        })
        .filter((item) => item)
      res = await this.services.vuln.vulListDelete({
        source_type: this.vulnType === 'vuln' ? 1 : 2,
        ids: String(ids),
      })

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private recheck(type: string) {
    this.$confirm(
      `${this.$t('views.vulnList.will')}${
        type === 'all'
          ? this.$t('views.vulnList.all')
          : this.$t('views.vulnList.batch')
      }${this.$t('views.vulnList.recheckDesc')}`,
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: this.$t(
          'views.vulnList.confirmButtonText'
        ) as string,
        cancelButtonText: this.$t('views.vulnList.cancelButtonText') as string,
        type: 'warning',
      }
    ).then(async () => {
      let res: any = {}
      if (type === 'all') {
        res = await this.services.vuln.vulRecheckAll({ type })
      } else {
        if (!this.tableData.some((item: any) => item.checked)) {
          this.$message({
            type: 'warning',
            message: this.$t('views.vulnList.chooseWarning') as string,
            showClose: true,
          })
          return
        }
        const ids = this.tableData
          .map((item) => {
            if (item.checked) {
              return item.id
            }
          })
          .filter((item) => item)
        res = await this.services.vuln.recheck({
          ids: String(ids),
        })
      }

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private selectAll(e: any) {
    const flag =
      this.tableData.length > 0 && this.tableData.every((item) => item.checked)
    if (flag) {
      this.tableData.forEach((item) => this.$set(item, 'checked', false))
    } else {
      this.tableData.forEach((item) => this.$set(item, 'checked', true))
    }
    console.log(e)
  }

  private newSelectData() {
    this.page = 1
    this.dataEnd = false
    this.tableData = []
    this.getTableData()
  }

  mounted() {
    window.addEventListener('scroll', this.myScroll)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.myScroll)
  }

  private myScroll() {
    const bottomWindow =
      document.documentElement.scrollTop + window.innerHeight >
      document.documentElement.offsetHeight - 1
    if (bottomWindow) {
      if (!this.dataEnd) {
        this.page += 1
        this.getTableData()
      }
    }
  }
  private async getTableData(reflash = false) {
    let sort = undefined
    if (this.searchObj.sort === true) {
      sort = 1
    }
    if (this.searchObj.sort === false) {
      sort = 0
    }
    let params
    if (this.vulnType === 'scan') {
      params = {
        page: this.page,
        page_size: this.pageSize,
        bind_project_id: Number(this.projectId),
        project_version_id: Number(this.version),
        keywords: this.searchObj.keywords || undefined,
        project_id: this.searchObj.project_str.length
          ? this.searchObj.project_str
          : undefined,
        vul_level_id: this.searchObj.level_str.length
          ? this.searchObj.level_str
          : undefined,
        vul_type: this.searchObj.hook_type_str.length
          ? this.searchObj.hook_type_str
          : undefined,
        order_type_desc: sort,
      }
    } else {
      params = {
        page: this.page,
        pageSize: this.pageSize,
        bind_project_id: this.projectId,
        project_version_id: String(this.version),
        level_id_str: this.searchObj.level_str.join(',') || undefined,
        project_id_str: this.searchObj.project_str.join(',') || undefined,
        order_type: this.searchObj.order_type || undefined,
        keywords: this.searchObj.keywords || undefined,
        status_id_str: this.searchObj.status_str.join(',') || undefined,
        source_type_str: this.searchObj.source_type_str.join(',') || undefined,
        availability_str:
          this.searchObj.availability_str.join(',') || undefined,
        hook_type_id_str: this.searchObj.hook_type_str.join(',') || undefined,
        language_str: this.searchObj.language_str.join(',') || undefined,
        order_type_desc: sort,
        type: this.vulnType,
      }
    }
    this.loadingStart()
    const { status, data, msg, page } = await this.services.vuln.vulListContent(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    const tableData = (data.messages || data).reduce(
      (list: Array<any>, item: any) => {
        let vul_number = ''
        if (item.vul_cve_nums) {
          vul_number = `${item.vul_cve_nums.cnnvd} | ${item.vul_cve_nums.cnvd} | ${item.vul_cve_nums.cve} | ${item.vul_cve_nums.cwe}`
        }
        list.push({
          ...item,
          first_time: formatTimestamp(item.first_time),
          latest_time: getPassedTime(item.latest_time),
          vul_number: vul_number,
          id: item.id,
          latest_time_nyr: formatTimestamp(item.latest_time),
        })
        return list
      },
      []
    )

    if (tableData.length < 20) {
      this.dataEnd = true
    }
    if (reflash) {
      this.tableData = []
    }
    this.pageInfo = page
    this.tableData = [...this.tableData, ...tableData]
  }

  private SummaryCatch: any = {}
  private async vulnSummary() {
    this.searchOptionsObj.level = []
    this.searchOptionsObj.availability = []
    this.searchOptionsObj.hook_type = []
    this.searchOptionsObj.language = []
    this.searchOptionsObj.status = []
    this.searchOptionsObj.projects = []

    if (this.vulnType === 'vuln') {
      this.searchOptionsObj.orderOptions = [
        {
          label: '漏洞级别',
          value: 1,
        },
        {
          label: '发现时间',
          value: 2,
        },
        {
          label: '最新活跃',
          value: 3,
        },
        {
          label: '状态',
          value: 4,
        },
      ]
    } else {
      this.searchOptionsObj.orderOptions = [
        {
          label: '漏洞级别',
          value: 1,
        },
        {
          label: '发现时间',
          value: 2,
        },
        {
          label: '最新活跃',
          value: 3,
        },
      ]
    }
    let params
    params = {
      bind_project_id: this.projectId,
      project_version_id: String(this.version),
      type: this.vulnType,
    }
    if (this.vulnType === 'scan') {
      params = {
        bind_project_id: Number(this.projectId),
        project_version_id: Number(this.version),
        type: this.vulnType,
      }
    }
    this.loadingStart()
    let res
    if (this.SummaryCatch[this.vulnType + 'version:' + this.version]) {
      res = this.SummaryCatch[this.vulnType + 'version:' + this.version]
    } else {
      res = await this.services.vuln.vulSummaryNum(params)
    }
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    if (this.vulnType === 'scan') {
      this.SummaryCatch[this.vulnType] = res
      this.searchOptionsObj.projects =
        res.data.project_info && res.data.project_info.length
          ? res.data.project_info
          : this.searchOptionsObj.projects
      this.searchOptionsObj.hook_type =
        res.data.vul_type && res.data.vul_type.length
          ? res.data.vul_type
          : this.searchOptionsObj.hook_type
      this.searchOptionsObj.level =
        res.data.vul_level && res.data.vul_level.length
          ? res.data.vul_level
          : [
              {
                name: '高危',
                num: 0,
                id: 1,
              },
              {
                name: '中危',
                num: 0,
                id: 2,
              },
              {
                name: '低危',
                num: 0,
                id: 3,
              },
              {
                name: '无风险',
                num: 0,
                id: 4,
              },
              {
                name: '提示',
                num: 0,
                id: 5,
              },
            ]
      return
    }
    this.SummaryCatch[this.vulnType] = res

    const availability = Object.values(res.data.messages.availability || {})
    this.searchOptionsObj.level =
      res.data.messages.level && res.data.messages.level.length
        ? res.data.messages.level
        : [
            {
              name: '高危',
              num: 0,
              id: 1,
            },
            {
              name: '中危',
              num: 0,
              id: 2,
            },
            {
              name: '低危',
              num: 0,
              id: 3,
            },
            {
              name: '无风险',
              num: 0,
              id: 4,
            },
            {
              name: '提示',
              num: 0,
              id: 5,
            },
          ]
    this.searchOptionsObj.status =
      res.data.messages.status && res.data.messages.status.length
        ? res.data.messages.status
        : [
            {
              name: '待验证',
              num: 0,
              id: 1,
            },
            {
              name: '验证中',
              num: 0,
              id: 2,
            },
            {
              name: '已确认',
              num: 0,
              id: 3,
            },
            {
              name: '已忽略',
              num: 0,
              id: 4,
            },
            {
              name: '已处理',
              num: 0,
              id: 5,
            },
          ]

    this.searchOptionsObj.availability =
      availability && availability.length
        ? availability
        : this.searchOptionsObj.availability
    this.searchOptionsObj.hook_type =
      res.data.messages.hook_type && res.data.messages.hook_type.length
        ? res.data.messages.hook_type
        : this.searchOptionsObj.hook_type
    this.searchOptionsObj.language =
      res.data.messages.language && res.data.messages.language.length
        ? res.data.messages.language
        : [
            {
              name: 'JAVA',
              num: 0,
              id: 1,
            },
            {
              name: 'PYTHON',
              num: 0,
              id: 2,
            },
            {
              name: 'PHP',
              num: 0,
              id: 3,
            },
            {
              name: 'GO',
              num: 0,
              id: 4,
            },
          ]
    this.searchOptionsObj.projects =
      res.data.messages.project && res.data.messages.project.length
        ? res.data.messages.project
        : this.searchOptionsObj.projects
  }
}
